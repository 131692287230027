import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import OptOutForm from "../components/optOutForm"
import OptOutWrapper from "../components/styles/OptOutStyles"

const IndexPage = ({ data, pageContext }) => {
  const { optOutSingleton } = data.swapi

  const form = {
    placeholderEmail: "Email",
    placeholderMessage:
      "Please write your request related to processing of your data",
    emailError: "Please provide valid business email address",
    messageError:
      "Please write your request related to processing of your data",
    buttonText: "Send",
    thx: "Thanks! Our team will contact you as soon as possible.",
    formHeading: optOutSingleton.formHeading,
  }

  return (
    <Layout pageContext={pageContext}>
      <SEO title="Opt-Out" lang="en" />
      <div className="container">
        <OptOutWrapper>
          <h1 className="title">Opt-Out</h1>
          <p
            className="subtitle"
            dangerouslySetInnerHTML={{ __html: form.formHeading }}
          />
          {/* <OptOutForm form={form} /> */}
        </OptOutWrapper>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query optOutPageEN {
    swapi {
      optOutSingleton(lang: "en") {
        formHeading
      }
    }
  }
`
